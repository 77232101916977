<!-- eslint-disable vue/no-deprecated-dollar-listeners-api -->
<template>
  <div :class="`header-search-wrapper${simpleHeader ? ' simple-header' : ''}`">
    <nuxt-link :to="fixUrlForCurrentLocale('/')" class="sf-header__logo">
      <img
        :src="require('~/assets/logos/logo_bestdigit_small.png')"
        class="sf-header__logo-image"
        alt="Logo"
        height="72"
      />
    </nuxt-link>
    <div
      v-if="!simpleHeader"
      v-show="headerText"
      class="shipping-message"
      :class="{ 'shipping-message-visible': isHome && !checkSticky }"
    >
      <SfImage
        alt="header-text-icon"
        :src="headerImage || '/icons/truck.svg'"
        width="32"
        height="32"
        placeholder="/icons/truck.svg"
      />
      <span>{{ headerText }}</span>
    </div>
    <div
      v-if="!simpleHeader"
      class="search-bar-wrapper"
      :class="{ 'search-bar-homepage': isHome && !checkSticky }"
    >
      <span class="searchbar-message">
        {{ $t('Che cosa stai cercando?') }}
      </span>
      <div class="sf-search-bar-inner">
        <SearchInput
          class="sf-header__search"
          :is-search-open="isSearchOpen"
          v-on="$listeners"
          :category-filter="categoryFilter"
          :current-page="currentPage"
          @resetSearch="$emit('resetSearch')"
        />
      </div>
    </div>
    <div v-if="!simpleHeader" class="sf-header__icons">
      <SfButton
        v-e2e="'app-header-account'"
        class="sf-button--pure sf-header__action button-account"
        data-testid="accountIcon"
        aria-label="Account"
        @click="handleAccountClick"
      >
        <AccountIcon />
        <span v-if="isAuthenticated" class="button-account-text">
          {{ $t('My account') }}
        </span>
        <span v-else class="button-account-text">
          {{ $t('Accedi') }}
        </span>
      </SfButton>
      <SfButton
        v-e2e="'app-header-cart'"
        class="sf-button--pure sf-header__action button-cart"
        aria-label="Toggle cart sidebar"
        @click="toggleCartSidebar"
        type="button"
      >
        <CarrelloIcon />
        <span v-if="cartTotalItems" class="sf-items--number cart-items">
          ({{ cartTotalItems }})
        </span>
      </SfButton>

      <SfButton
        v-e2e="'app-header-wishlist'"
        class="sf-button--pure sf-header__action button-wishlist"
        data-testid="wishlistIcon"
        aria-label="Wishlist"
        @click="toggleWishlistSidebar"
      >
        <div
          class="wishlist-icon-container"
          @mouseenter="mouseWishlist(true)"
          @mouseleave="mouseWishlist(false)"
        >
          <FullWishlistIcon
            width="27.795"
            height="25.301"
            v-if="isWishlistOnHover"
          />
          <EmptyWishlistIcon width="27.795" height="25.301" v-else />
          <span
            v-if="wishlistTotalItems > 0"
            class="sf-items--number cart-items"
          >
            ({{ wishlistTotalItems }})
          </span>
        </div>
      </SfButton>
    </div>
  </div>
</template>

<script>
import { SfButton, SfImage } from '@storefront-ui/vue';
import {
  computed,
  useContext,
  useRouter,
  ref,
  watch,
  useFetch,
} from '@nuxtjs/composition-api';
import {
  contentGetters,
  useContent,
  useUser,
  wishlistGetters,
} from '@gemini-vsf/composables';
import { useCart, useUiState, useWishlist } from '~/composables';

import SearchInput from '@/components/Header/SearchBar/SearchInput.vue';
import {
  CarrelloIcon,
  AccountIcon,
  EmptyWishlistIcon,
  FullWishlistIcon,
} from '~/components/General/Icons';
import { mapMobileObserver } from '~/helpers/mobileObserver';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default {
  name: 'SearchBarCustom',
  components: {
    CarrelloIcon,
    AccountIcon,
    EmptyWishlistIcon,
    FullWishlistIcon,
    SfButton,
    SearchInput,
    SfImage,
  },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    simpleHeader: {
      type: Boolean,
      default: false,
    },
    categoryFilter: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    isHome: Boolean,
    checkSticky: Boolean,
    isSearchOpen: Boolean,
  },
  setup() {
    const router = useRouter();
    const {
      app: { localeRoute },
      $vsf: {
        $gemini: {
          config: {
            installo: { installoServicesCollection },
          },
        },
      },
    } = useContext();
    const { isAuthenticated } = useUser();
    const isMobile = computed(() => mapMobileObserver().isMobile.get());
    const { cart } = useCart();
    const isWishlistOnHover = ref(false);
    const cartTotalItems = ref(0);
    const { wishlist } = useWishlist();
    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(`headerText-${locale}`);
    const wishlistItems = computed(() =>
      wishlistGetters?.getItems(wishlist?.value)
    );
    const identifierContact = `headerText-${locale === 'it' ? 'it' : 'en-US'}`;
    const blockData = ref();

    const wishlistTotalItems = computed(() => wishlistItems?.value?.length);

    const accountIcon = computed(() =>
      isAuthenticated.value ? 'profile_fill' : 'profile'
    );
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } =
      useUiState();

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(localeRoute({ name: 'my-account' }));
      } else {
        toggleLoginModal();
      }
    };

    useFetch(async () => {
      await loadBlocks({ identifiers: [identifierContact] });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      try {
        blockData.value = blocksContent[identifierContact];
      } catch {
        console.warn('Could not load header text block data.');
      }
    });
    const headerImage = computed(() => blockData.value?.image);
    const headerText = computed(() => blockData.value?.text);

    watch(cart, (newValue, oldValue) => {
      if (cart.value && !!cart.value?.items && cart.value?.items?.length > 0) {
        let serviceCounter = 0;
        cart.value?.items?.forEach((cartItem) => {
          if (
            !!cartItem.product.categories &&
            cartItem.product.categories?.length > 0 &&
            cartItem.product.categories?.find(
              (c) => c.uid === installoServicesCollection
            )
          ) {
            serviceCounter += cartItem.quantity;
          }
          cartTotalItems.value = cart.value.total_quantity - serviceCounter;
        });
      }
      if (
        (!newValue && oldValue) ||
        (cart.value && cart.value?.items.length === 0)
      ) {
        cartTotalItems.value = 0;
      }
    });

    const mouseWishlist = (value) => {
      isWishlistOnHover.value = value;
    };

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      isMobile,
      accountIcon,
      isAuthenticated,
      isWishlistOnHover,
      handleAccountClick,
      toggleCartSidebar,
      toggleWishlistSidebar,
      mouseWishlist,
      cartTotalItems,
      wishlistTotalItems,
      headerImage,
      headerText,
      fixUrlForCurrentLocale,
    };
  },
  computed: {
    listeners() {
      return {
        // eslint-disable-next-line vue/no-deprecated-dollar-listeners-api
        ...this.$listeners,
      };
    },
  },
};
</script>

<style lang="scss">
.header-search-wrapper {
  display: flex;
  align-items: center;
  margin: 0 3rem;
  justify-content: space-between;
  .button-account {
    width: fit-content;
    .button-account-text {
      padding-left: 0.75rem;
    }
    @media (min-width: $desktop-min) {
      &:hover {
        .button-account-text {
          color: var(--c-primary);
        }
        svg {
          path {
            fill: var(--c-primary);
          }
        }
      }
    }
  }
  .button-cart {
    width: fit-content;
    @media (min-width: $desktop-min) {
      &:hover {
        svg {
          path {
            fill: var(--c-primary);
          }
        }
      }
    }
  }
  .button-wishlist {
    width: fit-content;
    .wishlist-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .sf-header__icons,
  .sf-header__logo {
    z-index: 7;
  }
  .shipping-message {
    display: flex;
    align-items: center;
    grid-gap: 0.4063rem;
    color: var(--c-grey-2);
    font-family: var(--font-family--secondary);
    font-size: var(--font-size--3xs);
    visibility: hidden;
    position: absolute;
    right: -1000px;
    gap: 5px;
  }
  .shipping-message-visible {
    position: unset;
    visibility: visible;
    transition: all 200ms 150ms;
    .sf-icon {
      margin-right: 10px;
    }
  }
  .search-bar-wrapper {
    z-index: 4;
    width: 100vw;
    height: auto;
    top: 42px;
    position: fixed;
    left: 0;
    transition: background-color 50ms, top ease-in 150ms, height linear 150ms;
    display: flex;
    justify-content: center;
    .sf-search-bar-inner {
      transition: all 150ms;
      width: 100%;
      max-width: 600px;
    }
    .searchbar-message {
      visibility: hidden;
      color: transparent;
      width: 0;
      transition: width ease-out 150ms;
      .sf-search-bar__input {
        transition: all 200ms;
        height: 50px;
      }
    }
    &.search-bar-homepage {
      transition: background-color 100ms 150ms, top ease-out 150ms 50ms,
        height linear 150ms 150ms;
      background-color: var(--c-grey-1);
      position: fixed;
      top: 190px;
      left: 0;
      width: 100%;
      max-width: 100vw;
      height: 100px;
      display: flex;
      align-items: center;
      .sf-search-bar-inner {
        max-width: 700px;
        transition: all 150ms;
        width: 100%;
        .sf-search-bar__input {
          max-width: 720px;
          border-radius: 32px;
          .custom-button {
            border-radius: 0 32px 32px 0;
          }
        }
      }
      .shipping-message {
        position: unset;
        visibility: visible;
        transition: all 150ms 150ms;
      }
      .searchbar-message {
        font-size: var(--font-size--xs);
        font-family: var(--font-family--primary);
        color: #000000;
        position: unset;
        width: 22%;
        visibility: visible;
        transition: visibility 150ms 200ms, color 150ms 200ms, width 150ms;
      }
    }
  }
  .sf-header__action {
    font-family: var(--font-family--secondary);
    color: var(--c-grey-2);
    font-size: var(--font-size--3xs);
    & > span.sf-icon {
      --icon-color: var(--c-grey-2);
      // This works to set color and line width of icons, but wishlist is still thicker than the others.
      //.sf-icon-path {
      //  & > path {
      //    stroke: var(--c-grey-2);
      //    stroke-width: 1;
      //  }
      //}
    }
    & > span.sf-items--number {
      color: var(--c-grey-2);
      font-size: var(--font-size--3xs);
    }
    &:hover {
      color: var(--c-primary);
      & > span.sf-icon,
      & > span.sf-items--number {
        --icon-color: var(--c-primary);
        color: var(--c-primary);
      }
    }
  }
  &.simple-header {
    justify-content: center;
    .sf-header__logo {
      margin: 1rem 0 0;
    }
  }
}
@media (max-width: $tablet-max) {
  .header-search-wrapper {
    height: 70px;
    .sf-header__logo {
      margin: 0 -75px 0 -35px;
      position: relative;
      left: 40px;
      &-image {
        aspect-ratio: 265 / 72;
        object-fit: cover;
      }
    }
    .shipping-message {
      display: none;
    }
    .sf-header__icons {
      display: flex;
      width: 50%;
      justify-content: flex-end;
      scale: 0.8;
      .sf-button {
        margin-left: 20px;
      }
      .button-account {
        order: 1;
        width: fit-content;
        .button-account-text {
          display: none;
        }
      }
      .button-cart {
        width: fit-content;
        order: 3;
      }
      .button-wishlist {
        width: fit-content;
        order: 2;
      }
    }
    .search-bar-wrapper {
      z-index: 6;
      width: 70%;
      top: 111px;
      position: fixed;
      left: 25%;
      background-color: var(--c-grey-8);
      .sf-search-bar-inner {
        max-width: 100%;
      }
      .searchbar-message {
        display: none;
        .sf-search-bar__input {
          height: 50px;
          max-width: 100%;
        }
      }
      &.search-bar-homepage {
        z-index: 6;
        width: 70%;
        height: auto;
        top: 111px;
        position: fixed;
        left: 25%;
        background-color: var(--c-grey-8);
        .sf-search-bar-inner {
          .sf-search-bar__input {
            max-width: 100%;
            height: 50px;
          }
        }
      }
    }
  }
}
@media (min-width: $desktop-l-min) and (max-width: $desktop-xl-min) {
  .header-search-wrapper {
    .search-bar-wrapper {
      .sf-search-bar-inner {
        max-width: 45%;
      }
    }
  }
}
@media (min-width: $desktop-min) and (max-width: ($desktop-l-min - 1)) {
  .header-search-wrapper {
    .search-bar-wrapper {
      .sf-search-bar-inner {
        max-width: 36%;
      }
    }
  }
}
@media (max-width: 420px) {
  .header-search-wrapper {
    .sf-header__logo {
      left: 10px;
      &-image {
        scale: 0.8;
      }
    }
  }
}
@media (max-width: 350px) {
  .header-search-wrapper {
    .sf-header__logo {
      left: 0;
      &-image {
        scale: 0.7;
      }
    }
  }
}
@media (max-width: 320px) {
  .header-search-wrapper {
    .sf-header__logo {
      left: -15px;
      &-image {
        scale: 0.6;
      }
    }
  }
}
@media (min-width: $desktop-min) and (max-width: 1580px) {
  .header-search-wrapper {
    .search-bar-wrapper {
      top: 50px;
      &.search-bar-homepage {
        top: 193px;
      }
    }
  }
}
.header-search-wrapper {
  .sf-header__icons {
    .sf-header__action {
      margin: 0;
      padding: 0 0.625rem;
      &.button-cart {
        padding: 0 0 0 0.625rem;
      }
      @media (min-width: $desktop-min) {
        padding: 0 1.25rem;
        &.button-cart {
          padding: 0 1.25rem;
        }
        &.button-wishlist {
          padding-right: 2.5rem;
        }
        &:not(:last-child) {
          border-right: 1px solid var(--c-grey-7);
        }
      }
    }
  }
}
</style>
